
if (import.meta.hot) {
  import.meta.hot.accept((mod) => {
    const router = import.meta.hot.data.router
    const generateRoutes = import.meta.hot.data.generateRoutes
    if (!router || !generateRoutes) {
      import.meta.hot.invalidate('[nuxt] Cannot replace routes because there is no active router. Reloading.')
      return
    }
    router.clearRoutes()
    const routes = generateRoutes(mod.default || mod)
    function addRoutes (routes) {
      for (const route of routes) {
        router.addRoute(route)
      }
      router.replace(router.currentRoute.value.fullPath)
    }
    if (routes && 'then' in routes) {
      routes.then(addRoutes)
    } else {
      addRoutes(routes)
    }
  })
}

export function handleHotUpdate(_router, _generateRoutes) {
  if (import.meta.hot) {
    import.meta.hot.data ||= {}
    import.meta.hot.data.router = _router
    import.meta.hot.data.generateRoutes = _generateRoutes
  }
}
import { default as indexSz8kG1bg_45gTnq7LAf68ZCj1gfBJYaCWr0UuqZGcJ_45RYMeta } from "/codebuild/output/src3991397678/src/packages/nuxt/pages/index.vue?macro=true";
import { default as loginSCfHRD2qPMLNn6T3TuDPzQ24b9sOoh7Fkkh0YsaXUk4Meta } from "/codebuild/output/src3991397678/src/packages/nuxt/pages/login.vue?macro=true";
import { default as verify0pp2_452cBp2kQKeKKZQrWzxMLWxUiIY5hQKfR1AiEs84Meta } from "/codebuild/output/src3991397678/src/packages/nuxt/pages/verify.vue?macro=true";
import { default as sitemap0XBSBOpMtUMCZImN871SNIIi7p0e7FKtvx1z0R9bXCQMeta } from "/codebuild/output/src3991397678/src/packages/nuxt/pages/sitemap.vue?macro=true";
import { default as about_45usw_45J1oP0u6CS0ptx_45X62IvxtOq2Au8OhbKOIiXpvLbG0Meta } from "/codebuild/output/src3991397678/src/packages/nuxt/pages/about-us.vue?macro=true";
import { default as registerp69OlmJAHKGPGuRmWba_45ZTiLWMZF8UWtXZldm1liflMMeta } from "/codebuild/output/src3991397678/src/packages/nuxt/pages/register.vue?macro=true";
import { default as complaints13jpEqGTz5oJcD_450b0YKT6wVCLnCGxbZuLoZNCGcMRAMeta } from "/codebuild/output/src3991397678/src/packages/nuxt/pages/complaints.vue?macro=true";
import { default as contact_45usY0jjRAedu7rab1I6WOrSQ_wHmnKC4sW54IFdk_45k0v_45QMeta } from "/codebuild/output/src3991397678/src/packages/nuxt/pages/contact-us.vue?macro=true";
import { default as index_45diDEpdN1rBwe244GzEgrmmVs7sSuB95PVeAsU97cesMeta } from "/codebuild/output/src3991397678/src/packages/nuxt/pages/media/index.vue?macro=true";
import { default as indexjVPzwlapmlFc_45ZjZY8_45_4500EuPU_BvP1Vjm7a2x9Ol4oMeta } from "/codebuild/output/src3991397678/src/packages/nuxt/pages/energy/index.vue?macro=true";
import { default as _91slug_93Uq6cGJ_45LHmmyUNgHolGyCxoBBS_tyr2UuG6ZzRhF9VQMeta } from "/codebuild/output/src3991397678/src/packages/nuxt/pages/media/[slug].vue?macro=true";
import { default as partnerships_45Pi_45nR35dtqtfp_Jlp_45zC63DIDQg_0NxayQTjk6lCcsMeta } from "/codebuild/output/src3991397678/src/packages/nuxt/pages/partnerships.vue?macro=true";
import { default as terms_45of_45usepsfcg_45ZclOu6QZY4kKV31aY7EQUjpHDKLVySbpifS6UMeta } from "/codebuild/output/src3991397678/src/packages/nuxt/pages/terms-of-use.vue?macro=true";
import { default as indexkD6zVgEP2_ho89RiAVBpAGtDL2_NIlz6w960twxnwgsMeta } from "/codebuild/output/src3991397678/src/packages/nuxt/pages/careers/index.vue?macro=true";
import { default as privacy_45policypekQBUkVlcTps8iBTVbbA63X44VUUq6M_45PNFkMRNq_AMeta } from "/codebuild/output/src3991397678/src/packages/nuxt/pages/privacy-policy.vue?macro=true";
import { default as reset_45passwordyITFmW7GXG9iJJd17AfNNtYkhUDnMbPNyTNmYY_Y_45iYMeta } from "/codebuild/output/src3991397678/src/packages/nuxt/pages/reset-password.vue?macro=true";
import { default as index4Ng3Bd5kw9Xv0QjlYvLOF3DUrhm2tZJaSzYzfMpw3QYMeta } from "/codebuild/output/src3991397678/src/packages/nuxt/pages/car-loans/index.vue?macro=true";
import { default as indexuum6QIABkmAy0w9_Pp8cCNDO1EPg_rysXQB4VfvM1OIMeta } from "/codebuild/output/src3991397678/src/packages/nuxt/pages/dashboard/index.vue?macro=true";
import { default as forgot_45password5cfknVx01gEG7nbGYj1ikYZvHRmRES8i2PcUAE5VMS4Meta } from "/codebuild/output/src3991397678/src/packages/nuxt/pages/forgot-password.vue?macro=true";
import { default as error0H3D89T1tllSZ6z7anD4UqdBu7K1vxftEZoGhXiejS8Meta } from "/codebuild/output/src3991397678/src/packages/nuxt/pages/energy/cdr/error.vue?macro=true";
import { default as index_4507AHKazna5C8rwww9XPyG2axdTHlXBVRUXbamp1uF8Meta } from "/codebuild/output/src3991397678/src/packages/nuxt/pages/home-loans/index.vue?macro=true";
import { default as _91slug_93vXXBXL3YgPBIk9pkpeugQdu0ZNgxD8g_458SdPRLTZpiAMeta } from "/codebuild/output/src3991397678/src/packages/nuxt/pages/[vertical]/[slug].vue?macro=true";
import { default as guidesXAWI0usz7LophIwhpJHX5zquRmdTOB3t6elXBbigmCsMeta } from "/codebuild/output/src3991397678/src/packages/nuxt/pages/[vertical]/guides.vue?macro=true";
import { default as collection_45noticehkM9_45lWdq4kTXXzAFXipsocrAne6wmFm07OtWVcMx9MMeta } from "/codebuild/output/src3991397678/src/packages/nuxt/pages/collection-notice.vue?macro=true";
import { default as index1i2n1_45wz7z0JFC7TSjEaJkRF2ca_zDiSXaJpPEk_L7IMeta } from "/codebuild/output/src3991397678/src/packages/nuxt/pages/credit-cards/index.vue?macro=true";
import { default as set_45password7Gipo6FK7shz55p0_RFtiYTtBo_gRXUCGCbVq_iuMZAMeta } from "/codebuild/output/src3991397678/src/packages/nuxt/pages/guest/set-password.vue?macro=true";
import { default as welcome_45backDMi__Vz5ye8lIz2ubsLtHZtcdDnuF_Te89VgsKubLUQMeta } from "/codebuild/output/src3991397678/src/packages/nuxt/pages/guest/welcome-back.vue?macro=true";
import { default as merge2Q97ukvXIpLf2k_q3a7QD1HpIkEhynpRXuwvrAYl7h4Meta } from "/codebuild/output/src3991397678/src/packages/nuxt/pages/authorization/merge.vue?macro=true";
import { default as indexJKDFhZpupOnnlvI_45Y603sg3Qb3PFp_45q09fHiWsMJJIoMeta } from "/codebuild/output/src3991397678/src/packages/nuxt/pages/car-insurance/index.vue?macro=true";
import { default as _91slug_93WRVleU_45komzdxe_455Ajprz6vv9FMq4ZqgqasV3P0SGnQMeta } from "/codebuild/output/src3991397678/src/packages/nuxt/pages/credit-cards/[slug].vue?macro=true";
import { default as post_45sale_45thank_45youxTehWXfbHQZAmiHc7NSwQojT9YfyJ2SMk0UilhZuxP0Meta } from "/codebuild/output/src3991397678/src/packages/nuxt/pages/post-sale-thank-you.vue?macro=true";
import { default as thank_45youJ4SSctdaG4V6Ki1YQCaTcsKkH8wfVKZtcj4qJIGQ95cMeta } from "/codebuild/output/src3991397678/src/packages/nuxt/pages/energy/cdr/thank-you.vue?macro=true";
import { default as indexEzMn8oDODE3YaTsuplyntcmMYkPggU_yxzm4umie_45WgMeta } from "/codebuild/output/src3991397678/src/packages/nuxt/pages/life-insurance/index.vue?macro=true";
import { default as indexilU_R8QY3SubXl8fO8M85Qp943sfqivhDPNPstSppTAMeta } from "/codebuild/output/src3991397678/src/packages/nuxt/pages/personal-loans/index.vue?macro=true";
import { default as _91slug_93b_456B5WV46sOEVHoAsw1hMZm3MWaBV1nXqgPibcvh_45HEMeta } from "/codebuild/output/src3991397678/src/packages/nuxt/pages/preview/media/[slug].vue?macro=true";
import { default as indexrNZwHXGfva1DgMLyBWmqGAPXBl2RQKTMSvtHyG9OWnoMeta } from "/codebuild/output/src3991397678/src/packages/nuxt/pages/dashboard/perks/index.vue?macro=true";
import { default as indexA6VTUoFeHhSncpZdp4_Px5F1is6FQnynWwRZlvqEQjAMeta } from "/codebuild/output/src3991397678/src/packages/nuxt/pages/expert-analysis/index.vue?macro=true";
import { default as _91author_93tMf1GRt8P38Dl7iU4c121FN0NzMBS2o0f4KFjo_bly0Meta } from "/codebuild/output/src3991397678/src/packages/nuxt/pages/media/author/[author].vue?macro=true";
import { default as callbackq2QbB4wfF1OXdGpUWG9Xmkiqd_45nTucBIwMrJloiAafUMeta } from "/codebuild/output/src3991397678/src/packages/nuxt/pages/authorization/callback.vue?macro=true";
import { default as buyer_45protection_45claimr0lx91zfmtU9mbJEaAZfCFW9_aLe3vxZ3m1kOqNDJQ0Meta } from "/codebuild/output/src3991397678/src/packages/nuxt/pages/buyer-protection-claim.vue?macro=true";
import { default as indexkHdO5uRVwGs3sdLoObh5uZWbyaG6qnZtH4lYKIP0J_45IMeta } from "/codebuild/output/src3991397678/src/packages/nuxt/pages/dashboard/quotes/index.vue?macro=true";
import { default as thank_45yound6pFbyA9Hyue1_45mx_45381en9AusT_45CAvElAxwVahmCwMeta } from "/codebuild/output/src3991397678/src/packages/nuxt/pages/energy/quote/thank-you.vue?macro=true";
import { default as errormYNTu2PQjelKHIL75QsC1i24OamcH_DrmIG7zeII_KIMeta } from "/codebuild/output/src3991397678/src/packages/nuxt/pages/health-insurance/error.vue?macro=true";
import { default as indexmA1_QAyhkXKnKemJuetnnC3B7_456VShK5fRxApv_45yLu8Meta } from "/codebuild/output/src3991397678/src/packages/nuxt/pages/health-insurance/index.vue?macro=true";
import { default as indexoCJkro_ptwaUevp_d1PPcbrKvhTbyXf8t2nEKwB8HoAMeta } from "/codebuild/output/src3991397678/src/packages/nuxt/pages/dashboard/rewards/index.vue?macro=true";
import { default as erroroaVsY_45_454gvQbbE6VQDv6AykgVl_1IntiolEI3kvhzmUMeta } from "/codebuild/output/src3991397678/src/packages/nuxt/pages/energy/application/error.vue?macro=true";
import { default as _91step_93lbLT_0cEUoP3_vRs95t7t0lFz3v0TQd97lzcfA6teKcMeta } from "/codebuild/output/src3991397678/src/packages/nuxt/pages/energy/quote/form/[step].vue?macro=true";
import { default as details6cdo9rYSVIGw2_45UsyHQSBkJAkhI1bCqpEpAVUoHamqIMeta } from "/codebuild/output/src3991397678/src/packages/nuxt/pages/energy/quote/form/details.vue?macro=true";
import { default as interest_45ratesv440uM_45DLY_45WITQpFkBa0k90PEYb1iqTuQ3ZJLn26G4Meta } from "/codebuild/output/src3991397678/src/packages/nuxt/pages/home-loans/interest-rates.vue?macro=true";
import { default as _91slug_93mGSThc_hcASDswIa_45_45mYCUFmWQOxmgXA4GIVu1hErpUMeta } from "/codebuild/output/src3991397678/src/packages/nuxt/pages/preview/[vertical]/[slug].vue?macro=true";
import { default as indexwLFW46f3nZ519lKQSkEFaOFy9xA6dxJeXv_PF3EbBsUMeta } from "/codebuild/output/src3991397678/src/packages/nuxt/pages/[vertical]/companies/index.vue?macro=true";
import { default as account_45settingsb_45VTOWSMBqAWf8Th_45YQKHEOA28dMvuqOvrpEeRPN_KYMeta } from "/codebuild/output/src3991397678/src/packages/nuxt/pages/dashboard/account-settings.vue?macro=true";
import { default as indexxxx3KrGNPNfXMEPv5nmMbFKouUJxLXhnFjXKiYX7p20Meta } from "/codebuild/output/src3991397678/src/packages/nuxt/pages/dashboard/onboarding/index.vue?macro=true";
import { default as live_45chatY2A6tshIwgUNNj5iKcrNTXVN19iLgyKwdfTXVESes_UMeta } from "/codebuild/output/src3991397678/src/packages/nuxt/pages/health-insurance/live-chat.vue?macro=true";
import { default as post_45saleDRJpLYnbdrUy0THzvTGGujpb1D4YaN8Xj3n21c_45CdnQMeta } from "/codebuild/output/src3991397678/src/packages/nuxt/pages/health-insurance/post-sale.vue?macro=true";
import { default as thank_45youP6zv2q3Am3TBHHe0L1vqXSw6FzVfZMPQ7QLPLe3Aau0Meta } from "/codebuild/output/src3991397678/src/packages/nuxt/pages/home-loans/quote/thank-you.vue?macro=true";
import { default as comprehensivePq16VAOYDPjUW6XvM7XQDtd9eVVPbxE6MnjceTCTmTAMeta } from "/codebuild/output/src3991397678/src/packages/nuxt/pages/car-insurance/comprehensive.vue?macro=true";
import { default as _91slug_93Zio1Bzut8Za33CEce0Wr9XK_3HcsFrKWjSioy4swb8QMeta } from "/codebuild/output/src3991397678/src/packages/nuxt/pages/credit-cards/preview/[slug].vue?macro=true";
import { default as addBsR61cOUTKKzerOrXXr5ncjkWSnxd2DXuZXrAIWLDj8Meta } from "/codebuild/output/src3991397678/src/packages/nuxt/pages/life-insurance/partners/add.vue?macro=true";
import { default as indexT2v25tWoHDQ4BrEB0sFNs2ymRG6ED9HOi1A7L3hLd1wMeta } from "/codebuild/output/src3991397678/src/packages/nuxt/pages/[vertical]/schedule-callback/index.vue?macro=true";
import { default as _91response_93_45GHNNrduTeg7gxuTKiwETIjH1Vla52j2no_8Gzf9kdIMeta } from "/codebuild/output/src3991397678/src/packages/nuxt/pages/[vertical]/schedule-callback/[response].vue?macro=true";
import { default as schedule_45callbackgaFmWPj7sQOlBMs_450pP0dPQ3WHmrZYwA1EGqUjeXFJMMeta } from "/codebuild/output/src3991397678/src/packages/nuxt/pages/[vertical]/schedule-callback.vue?macro=true";
import { default as _91slug_93wPY5n_5AopBzINWTmZtsAZMcdkTGtKbSPuRj2bHEbdoMeta } from "/codebuild/output/src3991397678/src/packages/nuxt/pages/credit-cards/redirect/[slug].vue?macro=true";
import { default as thank_45youJljRIvQqfTcD5bSauYxrWvALDhjEhBeZOSo4DtQ1hAsMeta } from "/codebuild/output/src3991397678/src/packages/nuxt/pages/energy/application/thank-you.vue?macro=true";
import { default as live_45chat_45blmR79yi2f69v3SSxyEv_KZfldmxoJx1QiF281XDsX6wMeta } from "/codebuild/output/src3991397678/src/packages/nuxt/pages/health-insurance/live-chat-b.vue?macro=true";
import { default as indexypQ0ZTKffCox_gWsWh7AcGmueFDAIbuehYlNOM_4577HoMeta } from "/codebuild/output/src3991397678/src/packages/nuxt/pages/home-loans/calculators/index.vue?macro=true";
import { default as _91step_93oFemxEvXoG9H21p72Jx5kWzXZHw7sd0tCo2VS_45VtSMIMeta } from "/codebuild/output/src3991397678/src/packages/nuxt/pages/home-loans/quote/form/[step].vue?macro=true";
import { default as _91slug_93nEaqGh2xEJuSNkC5tAgRFZqcN8vn4kBCok2ZIkawFEQMeta } from "/codebuild/output/src3991397678/src/packages/nuxt/pages/car-insurance/redirect/[slug].vue?macro=true";
import { default as indexq2uJokxYiAnAt9bVE1KQAnEmBLLoLWwd86CDzpdwc3IMeta } from "/codebuild/output/src3991397678/src/packages/nuxt/pages/health-insurance/offers/index.vue?macro=true";
import { default as addH9mPmsbBCfxSNj7si_45dn8NZiZPiVayH9f_CcASS_45qEUMeta } from "/codebuild/output/src3991397678/src/packages/nuxt/pages/health-insurance/partners/add.vue?macro=true";
import { default as _91type_93_45F7WPlIQVmA173W9tVKz6FY_tryq0eIhn1h6HEbcvrYMeta } from "/codebuild/output/src3991397678/src/packages/nuxt/pages/home-loans/calculators/[type].vue?macro=true";
import { default as detailsTnU0Qddzax_45KuqJCfy07Fkxt9afGBb1hflOmAXxmz50Meta } from "/codebuild/output/src3991397678/src/packages/nuxt/pages/home-loans/quote/form/details.vue?macro=true";
import { default as callbackEf96Jkac0sHJ5CaX7us8HQ1mnFTibqHLuqsT4fB3gNIMeta } from "/codebuild/output/src3991397678/src/packages/nuxt/pages/life-insurance/quote/callback.vue?macro=true";
import { default as digital_45eic_cGBoLi5r7kqr0f3k0TjmdtiwiI9aCgb_45Z1FV6p3YWoMeta } from "/codebuild/output/src3991397678/src/packages/nuxt/pages/energy/application/digital-eic.vue?macro=true";
import { default as thank_45youWwQiferv27vEq8h4J9dZf66Nd5nJNjCIoK1XmVh0F50Meta } from "/codebuild/output/src3991397678/src/packages/nuxt/pages/personal-loans/quote/thank-you.vue?macro=true";
import { default as _91step_93kS_GoAah_VnGvHnsrKW3jYsaIC_45lUz4FcdLHAmVNK1AMeta } from "/codebuild/output/src3991397678/src/packages/nuxt/pages/car-insurance/quote/form/[step].vue?macro=true";
import { default as _91author_93ssXtPS_45P91P6uA6WMI05pRYv3Gr6X3D1UxpLHsu1LhQMeta } from "/codebuild/output/src3991397678/src/packages/nuxt/pages/expert-analysis/author/[author].vue?macro=true";
import { default as comparisondFKC68LNrZHCwo1HdBKq1j1iw0cJketdXCmyOVUIvDEMeta } from "/codebuild/output/src3991397678/src/packages/nuxt/pages/life-insurance/quote/comparison.vue?macro=true";
import { default as step1c6cHQ8vxKMgmnnorQ9R9_ybQMgsX795YJSwWZlaWZ8IMeta } from "/codebuild/output/src3991397678/src/packages/nuxt/pages/life-insurance/quote/form/step1.vue?macro=true";
import { default as detailsXmNTOiQwWHG6hq3MOWTXzDud6Suu8IDnWvrXoQE_45b_45YMeta } from "/codebuild/output/src3991397678/src/packages/nuxt/pages/car-insurance/quote/form/details.vue?macro=true";
import { default as resultsGVzLQpyAYHstYkLQBNSyUq44ELoqZ_aKoBXtv6wnDE4Meta } from "/codebuild/output/src3991397678/src/packages/nuxt/pages/car-insurance/quote/form/results.vue?macro=true";
import { default as indexKqhNtcIvA6grPXiaBk_LTsD64uWPe4P0FzRJBV5Bm8AMeta } from "/codebuild/output/src3991397678/src/packages/nuxt/pages/expert-analysis/[category]/index.vue?macro=true";
import { default as signupPv0e7mP5liZ95INUUfJgoCx3FTCVMaIGXux0iKtvzWMMeta } from "/codebuild/output/src3991397678/src/packages/nuxt/pages/health-insurance/partners/signup.vue?macro=true";
import { default as thank_45you_45LoCWsxtOjNOcPmm0xGgpaaOnVuxiApyJTk29Iuy9GgMeta } from "/codebuild/output/src3991397678/src/packages/nuxt/pages/health-insurance/quote/thank-you.vue?macro=true";
import { default as _91step_93IyuwvGENWUyemoqoRvi_45deiE2UH8Eybs1qQh64Wbf0UMeta } from "/codebuild/output/src3991397678/src/packages/nuxt/pages/personal-loans/quote/form/[step].vue?macro=true";
import { default as _91slug_935DIfCYAEttj9U6szhQFKIXYWGT9Fa7SwdbKf_g0LH6MMeta } from "/codebuild/output/src3991397678/src/packages/nuxt/pages/expert-analysis/[category]/[slug].vue?macro=true";
import { default as step0V5Fk2pPPf_45kaxZLBkcveGRnGBRShd1wg0M25t2u66jMMeta } from "/codebuild/output/src3991397678/src/packages/nuxt/pages/health-insurance/quote/form/step0.vue?macro=true";
import { default as existing_45customers0nh_QFHZ6yLDIhOObzPzU6v_kQGm1t3IiNUpPWCX1k8Meta } from "/codebuild/output/src3991397678/src/packages/nuxt/pages/life-insurance/existing-customers.vue?macro=true";
import { default as thank_45youdEClUZubP_iwc76fLTb9Z1ZUuXoQCxwFYQH7yOSSz6MMeta } from "/codebuild/output/src3991397678/src/packages/nuxt/pages/life-insurance/partners/thank-you.vue?macro=true";
import { default as detailsmSEyqOckpXDTKQX3g_45WcOrxrph3v7_x4AiRRIpLKWP0Meta } from "/codebuild/output/src3991397678/src/packages/nuxt/pages/life-insurance/quote/form/details.vue?macro=true";
import { default as detailsXPRSv4cfcucCMxw3_i7m_45BFNgTg5PYPIrnCGMq9MAcEMeta } from "/codebuild/output/src3991397678/src/packages/nuxt/pages/personal-loans/quote/form/details.vue?macro=true";
import { default as _91step_93srzbZ8xi8_45t0r7e5GFAE8htDR8DwtSyKmrXSuA_g2uAMeta } from "/codebuild/output/src3991397678/src/packages/nuxt/pages/health-insurance/quote/form/[step].vue?macro=true";
import { default as rebateFpkNmtm41D1PvIT7ERTgJZ6suShhX1JbAKCwNLfftAcMeta } from "/codebuild/output/src3991397678/src/packages/nuxt/pages/dashboard/vault/[...product]/rebate.vue?macro=true";
import { default as _91step_93DRECfD7EIY6BrooYruUN0fWM1X1EaUFCXr8VCwWhQuwMeta } from "/codebuild/output/src3991397678/src/packages/nuxt/pages/health-insurance/application/[step].vue?macro=true";
import { default as details8Fj2ynho0gb9G0DlV7kvEMGlPAcMM1tM8KHA_4v1S14Meta } from "/codebuild/output/src3991397678/src/packages/nuxt/pages/health-insurance/quote/form/details.vue?macro=true";
import { default as step099l5o_45ICgDMlVcZmh3SPaPLDxEug2jaKfyp4_Yf3vcgMeta } from "/codebuild/output/src3991397678/src/packages/nuxt/pages/health-insurance/quote/policy/step0.vue?macro=true";
import { default as indexMV27WxaNKf5_45av8NOlXegwRS8fM67A1AtLxb6F92qbAMeta } from "/codebuild/output/src3991397678/src/packages/nuxt/pages/health-insurance/quote/resume/index.vue?macro=true";
import { default as indexvpmvgwj3tsZCANHgH6wbL48brrEh6pq_45ddJszQ2PV_4Meta } from "/codebuild/output/src3991397678/src/packages/nuxt/pages/life-insurance/quote/customer/index.vue?macro=true";
import { default as detailsAQP0xBoKe6_45F3uc9f3dHmoA77QA9BhQd_45okljZM44F8Meta } from "/codebuild/output/src3991397678/src/packages/nuxt/pages/dashboard/vault/[...product]/details.vue?macro=true";
import { default as terms_45and_45conditionsEYN6E69rHLQpGWJce4gTZ0ieH5mYtjfJo34MJUjRQ_450Meta } from "/codebuild/output/src3991397678/src/packages/nuxt/pages/buyer-protection/terms-and-conditions.vue?macro=true";
import { default as _91step_93RwbavCcsG7bM1Go329uvIQZuS8seGBxZCIXslYMKwbwMeta } from "/codebuild/output/src3991397678/src/packages/nuxt/pages/health-insurance/applicationV2/[step].vue?macro=true";
import { default as index8CgAD3qH6qZJfI39lwuN4BKkeAs_NBz5OlvzQW1N9TAMeta } from "/codebuild/output/src3991397678/src/packages/nuxt/pages/health-insurance/quote/customer/index.vue?macro=true";
import { default as details5wOxEd0YEx_CtjQ6iVpjI4K0B4iHB7suMxh5RO18KGgMeta } from "/codebuild/output/src3991397678/src/packages/nuxt/pages/health-insurance/quote/policy/details.vue?macro=true";
import { default as indexQZ7vnhsjNWDo2xPVaZpNmbKDHtFj7LACudDtofoyiIQMeta } from "/codebuild/output/src3991397678/src/packages/nuxt/pages/health-insurance/quotes-results/index.vue?macro=true";
import { default as successVJkq5tc1ENaves6QAx3E3zSaUX10yvmzMuu2gbJTOZgMeta } from "/codebuild/output/src3991397678/src/packages/nuxt/pages/life-insurance/quote/customer/success.vue?macro=true";
import { default as claim_45offerdhW8WLZrH7gxi8yW5MvsxpfFOYiumEuPFHBMYqZ2U3UMeta } from "/codebuild/output/src3991397678/src/packages/nuxt/pages/dashboard/offers/[vertical]/claim-offer.vue?macro=true";
import { default as lic_45detailsB2ICtTzCsOQkgjttPl_453CVoWo8gC09w0TW5TMItha7gMeta } from "/codebuild/output/src3991397678/src/packages/nuxt/pages/dashboard/vault/[...product]/lic-details.vue?macro=true";
import { default as coffs_45harbourqWPy4d1zYiaFJP_45kihDZCoWLDYUUwP4b3jH4J2ly794Meta } from "/codebuild/output/src3991397678/src/packages/nuxt/pages/energy/compare-by-location/coffs-harbour.vue?macro=true";
import { default as _91step_93o1R4c35IaPDBihibgAMjochUvxTnF2Ztl3oLvXWQTLoMeta } from "/codebuild/output/src3991397678/src/packages/nuxt/pages/health-insurance/quote/policy/step/[step].vue?macro=true";
import { default as index1pPzw5dTFNTac3OpSjGQf1XZPeCe81O_ffIbTx8_45RCkMeta } from "/codebuild/output/src3991397678/src/packages/nuxt/pages/health-insurance/visitors-visa-ovhc/index.vue?macro=true";
import { default as _91slug_93aWRy_mOwnI4NtiT_e_tr7_4fVmHC83mRX6EwKjVTJZUMeta } from "/codebuild/output/src3991397678/src/packages/nuxt/pages/preview/expert-analysis/[vertical]/[slug].vue?macro=true";
import { default as indexuLDQTEq5ZhVVH5o_45o0Rf1b_45ykElalpiBSZZAWgFiEV8Meta } from "/codebuild/output/src3991397678/src/packages/nuxt/pages/health-insurance/application/payment/index.vue?macro=true";
import { default as _91step_93q87zrKkcpPP_5X1SlAMF7fkMEI6MNYw4_zVvoeojoKQMeta } from "/codebuild/output/src3991397678/src/packages/nuxt/pages/health-insurance/visitors-visa-ovhc/[step].vue?macro=true";
import { default as thank_45yougqrpS8NNNYx2T8pE4UMG5eP8FZCVW2NF7uPkOqQbCskMeta } from "/codebuild/output/src3991397678/src/packages/nuxt/pages/life-insurance/quote/application/thank-you.vue?macro=true";
import { default as indexOIge51TA78sBKOnvTJ72tjSrnHSf4PXDJrYwQQZd_PsMeta } from "/codebuild/output/src3991397678/src/packages/nuxt/pages/health-insurance/application/thankyou/index.vue?macro=true";
import { default as indexB4dlPdv6HiIlD0f9j5NryewFNn9kVqW9HJTOTlB7xsgMeta } from "/codebuild/output/src3991397678/src/packages/nuxt/pages/health-insurance/rate-rise-calculator/index.vue?macro=true";
import { default as detailsopj6XSJuLX5r4VABt8FWt13mP5YcZ0omqvdowgVuuSQMeta } from "/codebuild/output/src3991397678/src/packages/nuxt/pages/health-insurance/visitors-visa-ovhc/details.vue?macro=true";
import { default as expiredJukvf8fYoEWRPlEoQCeQ_45sUYXfxW_45hrkAjqT7S6pO04Meta } from "/codebuild/output/src3991397678/src/packages/nuxt/pages/health-insurance/application/payment/expired.vue?macro=true";
import { default as indexxJqPro6PMAhVeghfcyrE92az3CBDJ7xwyh5ARSqpTXcMeta } from "/codebuild/output/src3991397678/src/packages/nuxt/pages/life-insurance/income-protection/quote/index.vue?macro=true";
import { default as thank_45youbIe__BThAABflurXYBiiorvsbMt6_K3iZvcalyEiifwMeta } from "/codebuild/output/src3991397678/src/packages/nuxt/pages/health-insurance/visitors-visa-ovhc/thank-you.vue?macro=true";
import { default as detailsMCEBoNvH2OOwAQLYg1P3Gqctq5C9mYAAELBbc1ySHEcMeta } from "/codebuild/output/src3991397678/src/packages/nuxt/pages/life-insurance/income-protection/quote/details.vue?macro=true";
import { default as pre_45assesmentt4BPRNZEGxbdhNJ70fNaMyZOkgpbkF18t_45rvt21gWPsMeta } from "/codebuild/output/src3991397678/src/packages/nuxt/pages/life-insurance/quote/application/pre-assesment.vue?macro=true";
import { default as confirmationx_45PU2rGfQnZo4ghOgIIl6pwhQHmsCuQoRYSoZiU_45rdYMeta } from "/codebuild/output/src3991397678/src/packages/nuxt/pages/health-insurance/visitors-visa-ovhc/confirmation.vue?macro=true";
import { default as completionU2SEe2KpzrKc_NY_45uFPT6RwCrmcAFmo3u9B4k9ObWxsMeta } from "/codebuild/output/src3991397678/src/packages/nuxt/pages/dashboard/vault/[...product]/[vertical]/completion.vue?macro=true";
import { default as quotes_45resultsguWcdSVMutPMyR7WQ7kzZeN98ZCfxQKU5fr15oQz63cMeta } from "/codebuild/output/src3991397678/src/packages/nuxt/pages/health-insurance/visitors-visa-ovhc/quotes-results.vue?macro=true";
import { default as confirmationmO0hO9BwoXXlQzqG9AhqNwTyKDhr8P8OBu9SIlmAtRAMeta } from "/codebuild/output/src3991397678/src/packages/nuxt/pages/life-insurance/income-protection/quote/confirmation.vue?macro=true";
import { default as _91step_93wPUuWbRiO2FKFCe281xn7RjKL5_n6STGqq4MaJ4FhAMMeta } from "/codebuild/output/src3991397678/src/packages/nuxt/pages/dashboard/vault/[...product]/form/energy/step/[step].vue?macro=true";
import { default as _91step_937vhIUiTeq8tYUHFAgIHiRLGxjj6YI9FWRojFGYV1eIUMeta } from "/codebuild/output/src3991397678/src/packages/nuxt/pages/dashboard/vault/[...product]/form/generic/step/[step].vue?macro=true";
import { default as _91step_93Q_D4JFtwUK_41unj4_45UxfzgdDUcvFrF50cnjbkKtSKwMeta } from "/codebuild/output/src3991397678/src/packages/nuxt/pages/health-insurance/visitors-visa-ovhc/application/[step].vue?macro=true";
import { default as _91step_93ZwWeB8C5o1RHSFHFhKKDBnPRgCCed7d7htxf14VYCOUMeta } from "/codebuild/output/src3991397678/src/packages/nuxt/pages/dashboard/vault/[...product]/form/home-loan/step/[step].vue?macro=true";
import { default as indexHyEj_456SUrPCilUs35ZPDmPG5AQfURU6D_45Kz3rWz_Ni4Meta } from "/codebuild/output/src3991397678/src/packages/nuxt/pages/health-insurance/students-offer/[university]/form/index.vue?macro=true";
import { default as additional_45detailspo64WTaiy3bfeosuOur5sKkci_klFE0Zyg_45LUZyObx4Meta } from "/codebuild/output/src3991397678/src/packages/nuxt/pages/dashboard/vault/[...product]/[vertical]/additional-details.vue?macro=true";
import { default as _91step_930aIMZ6xXQuBBB2nqTNIDpkHXMFxBN3G_45lIb2MSn9FvUMeta } from "/codebuild/output/src3991397678/src/packages/nuxt/pages/dashboard/vault/[...product]/form/life-insurance/step/[step].vue?macro=true";
import { default as _91step_93sNn1aq_45_45L89B1xCLSlQYburuUA7OfKUJR_GD_458aaI6IMeta } from "/codebuild/output/src3991397678/src/packages/nuxt/pages/dashboard/vault/[...product]/form/health-insurance/step/[step].vue?macro=true";
import { default as indexK7GsbWFtoLoEGUSK_45dqAF66L7GOP2jfJbF9BSBtysa8Meta } from "/codebuild/output/src3991397678/src/packages/nuxt/pages/health-insurance/students-offer/[university]/terms-conditions/index.vue?macro=true";
import { default as indexrKEc18NPa3fIYQ2wl00ApvJacK86LF8qveFbcPxjpAMMeta } from "/codebuild/output/src3991397678/src/packages/nuxt/pages/health-insurance/budget-hub/health-insurance-average-cost-calculator/index.vue?macro=true";
export default [
  {
    name: "index",
    path: "/",
    component: () => import("/codebuild/output/src3991397678/src/packages/nuxt/pages/index.vue")
  },
  {
    name: "login",
    path: "/login",
    meta: loginSCfHRD2qPMLNn6T3TuDPzQ24b9sOoh7Fkkh0YsaXUk4Meta || {},
    component: () => import("/codebuild/output/src3991397678/src/packages/nuxt/pages/login.vue")
  },
  {
    name: "verify",
    path: "/verify",
    component: () => import("/codebuild/output/src3991397678/src/packages/nuxt/pages/verify.vue")
  },
  {
    name: "sitemap",
    path: "/sitemap",
    component: () => import("/codebuild/output/src3991397678/src/packages/nuxt/pages/sitemap.vue")
  },
  {
    name: "about-us",
    path: "/about-us",
    component: () => import("/codebuild/output/src3991397678/src/packages/nuxt/pages/about-us.vue")
  },
  {
    name: "register",
    path: "/register",
    meta: registerp69OlmJAHKGPGuRmWba_45ZTiLWMZF8UWtXZldm1liflMMeta || {},
    component: () => import("/codebuild/output/src3991397678/src/packages/nuxt/pages/register.vue")
  },
  {
    name: "complaints",
    path: "/complaints",
    component: () => import("/codebuild/output/src3991397678/src/packages/nuxt/pages/complaints.vue")
  },
  {
    name: "contact-us",
    path: "/contact-us",
    component: () => import("/codebuild/output/src3991397678/src/packages/nuxt/pages/contact-us.vue")
  },
  {
    name: "media",
    path: "/media",
    component: () => import("/codebuild/output/src3991397678/src/packages/nuxt/pages/media/index.vue")
  },
  {
    name: "energy",
    path: "/energy",
    component: () => import("/codebuild/output/src3991397678/src/packages/nuxt/pages/energy/index.vue")
  },
  {
    name: "media-slug",
    path: "/media/:slug()",
    component: () => import("/codebuild/output/src3991397678/src/packages/nuxt/pages/media/[slug].vue")
  },
  {
    name: "partnerships",
    path: "/partnerships",
    component: () => import("/codebuild/output/src3991397678/src/packages/nuxt/pages/partnerships.vue")
  },
  {
    name: "terms-of-use",
    path: "/terms-of-use",
    component: () => import("/codebuild/output/src3991397678/src/packages/nuxt/pages/terms-of-use.vue")
  },
  {
    name: "careers",
    path: "/careers",
    component: () => import("/codebuild/output/src3991397678/src/packages/nuxt/pages/careers/index.vue")
  },
  {
    name: "privacy-policy",
    path: "/privacy-policy",
    component: () => import("/codebuild/output/src3991397678/src/packages/nuxt/pages/privacy-policy.vue")
  },
  {
    name: "reset-password",
    path: "/reset-password",
    meta: reset_45passwordyITFmW7GXG9iJJd17AfNNtYkhUDnMbPNyTNmYY_Y_45iYMeta || {},
    component: () => import("/codebuild/output/src3991397678/src/packages/nuxt/pages/reset-password.vue")
  },
  {
    name: "car-loans",
    path: "/car-loans",
    component: () => import("/codebuild/output/src3991397678/src/packages/nuxt/pages/car-loans/index.vue")
  },
  {
    name: "dashboard",
    path: "/dashboard",
    meta: indexuum6QIABkmAy0w9_Pp8cCNDO1EPg_rysXQB4VfvM1OIMeta || {},
    component: () => import("/codebuild/output/src3991397678/src/packages/nuxt/pages/dashboard/index.vue")
  },
  {
    name: "forgot-password",
    path: "/forgot-password",
    meta: forgot_45password5cfknVx01gEG7nbGYj1ikYZvHRmRES8i2PcUAE5VMS4Meta || {},
    component: () => import("/codebuild/output/src3991397678/src/packages/nuxt/pages/forgot-password.vue")
  },
  {
    name: "energy-cdr-error",
    path: "/energy/cdr/error",
    component: () => import("/codebuild/output/src3991397678/src/packages/nuxt/pages/energy/cdr/error.vue")
  },
  {
    name: "home-loans",
    path: "/home-loans",
    component: () => import("/codebuild/output/src3991397678/src/packages/nuxt/pages/home-loans/index.vue")
  },
  {
    name: "vertical-slug",
    path: "/:vertical()/:slug()",
    component: () => import("/codebuild/output/src3991397678/src/packages/nuxt/pages/[vertical]/[slug].vue")
  },
  {
    name: "vertical-guides",
    path: "/:vertical()/guides",
    component: () => import("/codebuild/output/src3991397678/src/packages/nuxt/pages/[vertical]/guides.vue")
  },
  {
    name: "collection-notice",
    path: "/collection-notice",
    component: () => import("/codebuild/output/src3991397678/src/packages/nuxt/pages/collection-notice.vue")
  },
  {
    name: "credit-cards",
    path: "/credit-cards",
    component: () => import("/codebuild/output/src3991397678/src/packages/nuxt/pages/credit-cards/index.vue")
  },
  {
    name: "guest-set-password",
    path: "/guest/set-password",
    meta: set_45password7Gipo6FK7shz55p0_RFtiYTtBo_gRXUCGCbVq_iuMZAMeta || {},
    component: () => import("/codebuild/output/src3991397678/src/packages/nuxt/pages/guest/set-password.vue")
  },
  {
    name: "guest-welcome-back",
    path: "/guest/welcome-back",
    component: () => import("/codebuild/output/src3991397678/src/packages/nuxt/pages/guest/welcome-back.vue")
  },
  {
    name: "authorization-merge",
    path: "/authorization/merge",
    component: () => import("/codebuild/output/src3991397678/src/packages/nuxt/pages/authorization/merge.vue")
  },
  {
    name: "car-insurance",
    path: "/car-insurance",
    component: () => import("/codebuild/output/src3991397678/src/packages/nuxt/pages/car-insurance/index.vue")
  },
  {
    name: "credit-cards-slug",
    path: "/credit-cards/:slug()",
    meta: _91slug_93WRVleU_45komzdxe_455Ajprz6vv9FMq4ZqgqasV3P0SGnQMeta || {},
    component: () => import("/codebuild/output/src3991397678/src/packages/nuxt/pages/credit-cards/[slug].vue")
  },
  {
    name: "post-sale-thank-you",
    path: "/post-sale-thank-you",
    component: () => import("/codebuild/output/src3991397678/src/packages/nuxt/pages/post-sale-thank-you.vue")
  },
  {
    name: "energy-cdr-thank-you",
    path: "/energy/cdr/thank-you",
    component: () => import("/codebuild/output/src3991397678/src/packages/nuxt/pages/energy/cdr/thank-you.vue")
  },
  {
    name: "life-insurance",
    path: "/life-insurance",
    component: () => import("/codebuild/output/src3991397678/src/packages/nuxt/pages/life-insurance/index.vue")
  },
  {
    name: "personal-loans",
    path: "/personal-loans",
    component: () => import("/codebuild/output/src3991397678/src/packages/nuxt/pages/personal-loans/index.vue")
  },
  {
    name: "preview-media-slug",
    path: "/preview/media/:slug()",
    component: () => import("/codebuild/output/src3991397678/src/packages/nuxt/pages/preview/media/[slug].vue")
  },
  {
    name: "dashboard-perks",
    path: "/dashboard/perks",
    meta: indexrNZwHXGfva1DgMLyBWmqGAPXBl2RQKTMSvtHyG9OWnoMeta || {},
    component: () => import("/codebuild/output/src3991397678/src/packages/nuxt/pages/dashboard/perks/index.vue")
  },
  {
    name: "expert-analysis",
    path: "/expert-analysis",
    component: () => import("/codebuild/output/src3991397678/src/packages/nuxt/pages/expert-analysis/index.vue")
  },
  {
    name: "media-author-author",
    path: "/media/author/:author()",
    component: () => import("/codebuild/output/src3991397678/src/packages/nuxt/pages/media/author/[author].vue")
  },
  {
    name: "authorization-callback",
    path: "/authorization/callback",
    component: () => import("/codebuild/output/src3991397678/src/packages/nuxt/pages/authorization/callback.vue")
  },
  {
    name: "buyer-protection-claim",
    path: "/buyer-protection-claim",
    component: () => import("/codebuild/output/src3991397678/src/packages/nuxt/pages/buyer-protection-claim.vue")
  },
  {
    name: "dashboard-quotes",
    path: "/dashboard/quotes",
    meta: indexkHdO5uRVwGs3sdLoObh5uZWbyaG6qnZtH4lYKIP0J_45IMeta || {},
    component: () => import("/codebuild/output/src3991397678/src/packages/nuxt/pages/dashboard/quotes/index.vue")
  },
  {
    name: "energy-quote-thank-you",
    path: "/energy/quote/thank-you",
    component: () => import("/codebuild/output/src3991397678/src/packages/nuxt/pages/energy/quote/thank-you.vue")
  },
  {
    name: "health-insurance-error",
    path: "/health-insurance/error",
    component: () => import("/codebuild/output/src3991397678/src/packages/nuxt/pages/health-insurance/error.vue")
  },
  {
    name: "health-insurance",
    path: "/health-insurance",
    component: () => import("/codebuild/output/src3991397678/src/packages/nuxt/pages/health-insurance/index.vue")
  },
  {
    name: "dashboard-rewards",
    path: "/dashboard/rewards",
    props: indexoCJkro_ptwaUevp_d1PPcbrKvhTbyXf8t2nEKwB8HoAMeta?.props ?? {"verificationNeeded":true},
    meta: indexoCJkro_ptwaUevp_d1PPcbrKvhTbyXf8t2nEKwB8HoAMeta || {},
    component: () => import("/codebuild/output/src3991397678/src/packages/nuxt/pages/dashboard/rewards/index.vue")
  },
  {
    name: "energy-application-error",
    path: "/energy/application/error",
    component: () => import("/codebuild/output/src3991397678/src/packages/nuxt/pages/energy/application/error.vue")
  },
  {
    name: "energy-quote-form-step",
    path: "/energy/quote/form/:step()",
    component: () => import("/codebuild/output/src3991397678/src/packages/nuxt/pages/energy/quote/form/[step].vue")
  },
  {
    name: "energy-quote-form-details",
    path: "/energy/quote/form/details",
    component: () => import("/codebuild/output/src3991397678/src/packages/nuxt/pages/energy/quote/form/details.vue")
  },
  {
    name: "home-loans-interest-rates",
    path: "/home-loans/interest-rates",
    component: () => import("/codebuild/output/src3991397678/src/packages/nuxt/pages/home-loans/interest-rates.vue")
  },
  {
    name: "preview-vertical-slug",
    path: "/preview/:vertical()/:slug()",
    component: () => import("/codebuild/output/src3991397678/src/packages/nuxt/pages/preview/[vertical]/[slug].vue")
  },
  {
    name: "vertical-companies",
    path: "/:vertical()/companies",
    component: () => import("/codebuild/output/src3991397678/src/packages/nuxt/pages/[vertical]/companies/index.vue")
  },
  {
    name: "dashboard-account-settings",
    path: "/dashboard/account-settings",
    meta: account_45settingsb_45VTOWSMBqAWf8Th_45YQKHEOA28dMvuqOvrpEeRPN_KYMeta || {},
    component: () => import("/codebuild/output/src3991397678/src/packages/nuxt/pages/dashboard/account-settings.vue")
  },
  {
    name: "dashboard-onboarding",
    path: "/dashboard/onboarding",
    meta: indexxxx3KrGNPNfXMEPv5nmMbFKouUJxLXhnFjXKiYX7p20Meta || {},
    component: () => import("/codebuild/output/src3991397678/src/packages/nuxt/pages/dashboard/onboarding/index.vue")
  },
  {
    name: "health-insurance-live-chat",
    path: "/health-insurance/live-chat",
    component: () => import("/codebuild/output/src3991397678/src/packages/nuxt/pages/health-insurance/live-chat.vue")
  },
  {
    name: "health-insurance-post-sale",
    path: "/health-insurance/post-sale",
    component: () => import("/codebuild/output/src3991397678/src/packages/nuxt/pages/health-insurance/post-sale.vue")
  },
  {
    name: "home-loans-quote-thank-you",
    path: "/home-loans/quote/thank-you",
    component: () => import("/codebuild/output/src3991397678/src/packages/nuxt/pages/home-loans/quote/thank-you.vue")
  },
  {
    name: "car-insurance-comprehensive",
    path: "/car-insurance/comprehensive",
    component: () => import("/codebuild/output/src3991397678/src/packages/nuxt/pages/car-insurance/comprehensive.vue")
  },
  {
    name: "credit-cards-preview-slug",
    path: "/credit-cards/preview/:slug()",
    component: () => import("/codebuild/output/src3991397678/src/packages/nuxt/pages/credit-cards/preview/[slug].vue")
  },
  {
    name: "life-insurance-partners-add",
    path: "/life-insurance/partners/add",
    component: () => import("/codebuild/output/src3991397678/src/packages/nuxt/pages/life-insurance/partners/add.vue")
  },
  {
    name: schedule_45callbackgaFmWPj7sQOlBMs_450pP0dPQ3WHmrZYwA1EGqUjeXFJMMeta?.name,
    path: "/:vertical()/schedule-callback",
    component: () => import("/codebuild/output/src3991397678/src/packages/nuxt/pages/[vertical]/schedule-callback.vue"),
    children: [
  {
    name: "vertical-schedule-callback",
    path: "",
    component: () => import("/codebuild/output/src3991397678/src/packages/nuxt/pages/[vertical]/schedule-callback/index.vue")
  },
  {
    name: "vertical-schedule-callback-response",
    path: ":response()",
    component: () => import("/codebuild/output/src3991397678/src/packages/nuxt/pages/[vertical]/schedule-callback/[response].vue")
  }
]
  },
  {
    name: "credit-cards-redirect-slug",
    path: "/credit-cards/redirect/:slug()",
    component: () => import("/codebuild/output/src3991397678/src/packages/nuxt/pages/credit-cards/redirect/[slug].vue")
  },
  {
    name: "energy-application-thank-you",
    path: "/energy/application/thank-you",
    component: () => import("/codebuild/output/src3991397678/src/packages/nuxt/pages/energy/application/thank-you.vue")
  },
  {
    name: "health-insurance-live-chat-b",
    path: "/health-insurance/live-chat-b",
    component: () => import("/codebuild/output/src3991397678/src/packages/nuxt/pages/health-insurance/live-chat-b.vue")
  },
  {
    name: "home-loans-calculators",
    path: "/home-loans/calculators",
    component: () => import("/codebuild/output/src3991397678/src/packages/nuxt/pages/home-loans/calculators/index.vue")
  },
  {
    name: "home-loans-quote-form-step",
    path: "/home-loans/quote/form/:step()",
    component: () => import("/codebuild/output/src3991397678/src/packages/nuxt/pages/home-loans/quote/form/[step].vue")
  },
  {
    name: "car-insurance-redirect-slug",
    path: "/car-insurance/redirect/:slug()",
    component: () => import("/codebuild/output/src3991397678/src/packages/nuxt/pages/car-insurance/redirect/[slug].vue")
  },
  {
    name: "health-insurance-offers",
    path: "/health-insurance/offers",
    component: () => import("/codebuild/output/src3991397678/src/packages/nuxt/pages/health-insurance/offers/index.vue")
  },
  {
    name: "health-insurance-partners-add",
    path: "/health-insurance/partners/add",
    component: () => import("/codebuild/output/src3991397678/src/packages/nuxt/pages/health-insurance/partners/add.vue")
  },
  {
    name: "home-loans-calculators-type",
    path: "/home-loans/calculators/:type()",
    component: () => import("/codebuild/output/src3991397678/src/packages/nuxt/pages/home-loans/calculators/[type].vue")
  },
  {
    name: "home-loans-quote-form-details",
    path: "/home-loans/quote/form/details",
    component: () => import("/codebuild/output/src3991397678/src/packages/nuxt/pages/home-loans/quote/form/details.vue")
  },
  {
    name: "life-insurance-quote-callback",
    path: "/life-insurance/quote/callback",
    component: () => import("/codebuild/output/src3991397678/src/packages/nuxt/pages/life-insurance/quote/callback.vue")
  },
  {
    name: "energy-application-digital-eic",
    path: "/energy/application/digital-eic",
    component: () => import("/codebuild/output/src3991397678/src/packages/nuxt/pages/energy/application/digital-eic.vue")
  },
  {
    name: "personal-loans-quote-thank-you",
    path: "/personal-loans/quote/thank-you",
    component: () => import("/codebuild/output/src3991397678/src/packages/nuxt/pages/personal-loans/quote/thank-you.vue")
  },
  {
    name: "car-insurance-quote-form-step",
    path: "/car-insurance/quote/form/:step()",
    component: () => import("/codebuild/output/src3991397678/src/packages/nuxt/pages/car-insurance/quote/form/[step].vue")
  },
  {
    name: "expert-analysis-author-author",
    path: "/expert-analysis/author/:author()",
    component: () => import("/codebuild/output/src3991397678/src/packages/nuxt/pages/expert-analysis/author/[author].vue")
  },
  {
    name: "life-insurance-quote-comparison",
    path: "/life-insurance/quote/comparison",
    component: () => import("/codebuild/output/src3991397678/src/packages/nuxt/pages/life-insurance/quote/comparison.vue")
  },
  {
    name: "life-insurance-quote-form-step1",
    path: "/life-insurance/quote/form/step1",
    component: () => import("/codebuild/output/src3991397678/src/packages/nuxt/pages/life-insurance/quote/form/step1.vue")
  },
  {
    name: "car-insurance-quote-form-details",
    path: "/car-insurance/quote/form/details",
    component: () => import("/codebuild/output/src3991397678/src/packages/nuxt/pages/car-insurance/quote/form/details.vue")
  },
  {
    name: "car-insurance-quote-form-results",
    path: "/car-insurance/quote/form/results",
    component: () => import("/codebuild/output/src3991397678/src/packages/nuxt/pages/car-insurance/quote/form/results.vue")
  },
  {
    name: "expert-analysis-category",
    path: "/expert-analysis/:category()",
    component: () => import("/codebuild/output/src3991397678/src/packages/nuxt/pages/expert-analysis/[category]/index.vue")
  },
  {
    name: "health-insurance-partners-signup",
    path: "/health-insurance/partners/signup",
    component: () => import("/codebuild/output/src3991397678/src/packages/nuxt/pages/health-insurance/partners/signup.vue")
  },
  {
    name: "health-insurance-quote-thank-you",
    path: "/health-insurance/quote/thank-you",
    component: () => import("/codebuild/output/src3991397678/src/packages/nuxt/pages/health-insurance/quote/thank-you.vue")
  },
  {
    name: "personal-loans-quote-form-step",
    path: "/personal-loans/quote/form/:step()",
    component: () => import("/codebuild/output/src3991397678/src/packages/nuxt/pages/personal-loans/quote/form/[step].vue")
  },
  {
    name: "expert-analysis-category-slug",
    path: "/expert-analysis/:category()/:slug()",
    component: () => import("/codebuild/output/src3991397678/src/packages/nuxt/pages/expert-analysis/[category]/[slug].vue")
  },
  {
    name: "health-insurance-quote-form-step0",
    path: "/health-insurance/quote/form/step0",
    component: () => import("/codebuild/output/src3991397678/src/packages/nuxt/pages/health-insurance/quote/form/step0.vue")
  },
  {
    name: "life-insurance-existing-customers",
    path: "/life-insurance/existing-customers",
    component: () => import("/codebuild/output/src3991397678/src/packages/nuxt/pages/life-insurance/existing-customers.vue")
  },
  {
    name: "life-insurance-partners-thank-you",
    path: "/life-insurance/partners/thank-you",
    component: () => import("/codebuild/output/src3991397678/src/packages/nuxt/pages/life-insurance/partners/thank-you.vue")
  },
  {
    name: "life-insurance-quote-form-details",
    path: "/life-insurance/quote/form/details",
    component: () => import("/codebuild/output/src3991397678/src/packages/nuxt/pages/life-insurance/quote/form/details.vue")
  },
  {
    name: "personal-loans-quote-form-details",
    path: "/personal-loans/quote/form/details",
    component: () => import("/codebuild/output/src3991397678/src/packages/nuxt/pages/personal-loans/quote/form/details.vue")
  },
  {
    name: "health-insurance-quote-form-step",
    path: "/health-insurance/quote/form/:step()",
    component: () => import("/codebuild/output/src3991397678/src/packages/nuxt/pages/health-insurance/quote/form/[step].vue")
  },
  {
    name: "dashboard-vault-product-rebate",
    path: "/dashboard/vault/:product([^/]*)*/rebate",
    meta: rebateFpkNmtm41D1PvIT7ERTgJZ6suShhX1JbAKCwNLfftAcMeta || {},
    component: () => import("/codebuild/output/src3991397678/src/packages/nuxt/pages/dashboard/vault/[...product]/rebate.vue")
  },
  {
    name: "health-insurance-application-step",
    path: "/health-insurance/application/:step()",
    component: () => import("/codebuild/output/src3991397678/src/packages/nuxt/pages/health-insurance/application/[step].vue")
  },
  {
    name: "health-insurance-quote-form-details",
    path: "/health-insurance/quote/form/details",
    component: () => import("/codebuild/output/src3991397678/src/packages/nuxt/pages/health-insurance/quote/form/details.vue")
  },
  {
    name: "health-insurance-quote-policy-step0",
    path: "/health-insurance/quote/policy/step0",
    component: () => import("/codebuild/output/src3991397678/src/packages/nuxt/pages/health-insurance/quote/policy/step0.vue")
  },
  {
    name: "health-insurance-quote-resume",
    path: "/health-insurance/quote/resume",
    component: () => import("/codebuild/output/src3991397678/src/packages/nuxt/pages/health-insurance/quote/resume/index.vue")
  },
  {
    name: "life-insurance-quote-customer",
    path: "/life-insurance/quote/customer",
    component: () => import("/codebuild/output/src3991397678/src/packages/nuxt/pages/life-insurance/quote/customer/index.vue")
  },
  {
    name: "dashboard-vault-product-details",
    path: "/dashboard/vault/:product([^/]*)*/details",
    meta: detailsAQP0xBoKe6_45F3uc9f3dHmoA77QA9BhQd_45okljZM44F8Meta || {},
    component: () => import("/codebuild/output/src3991397678/src/packages/nuxt/pages/dashboard/vault/[...product]/details.vue")
  },
  {
    name: "buyer-protection-terms-and-conditions",
    path: "/buyer-protection/terms-and-conditions",
    component: () => import("/codebuild/output/src3991397678/src/packages/nuxt/pages/buyer-protection/terms-and-conditions.vue")
  },
  {
    name: "health-insurance-applicationV2-step",
    path: "/health-insurance/applicationV2/:step()",
    component: () => import("/codebuild/output/src3991397678/src/packages/nuxt/pages/health-insurance/applicationV2/[step].vue")
  },
  {
    name: "health-insurance-quote-customer",
    path: "/health-insurance/quote/customer",
    component: () => import("/codebuild/output/src3991397678/src/packages/nuxt/pages/health-insurance/quote/customer/index.vue")
  },
  {
    name: "health-insurance-quote-policy-details",
    path: "/health-insurance/quote/policy/details",
    component: () => import("/codebuild/output/src3991397678/src/packages/nuxt/pages/health-insurance/quote/policy/details.vue")
  },
  {
    name: "health-insurance-quotes-results",
    path: "/health-insurance/quotes-results",
    component: () => import("/codebuild/output/src3991397678/src/packages/nuxt/pages/health-insurance/quotes-results/index.vue")
  },
  {
    name: "life-insurance-quote-customer-success",
    path: "/life-insurance/quote/customer/success",
    component: () => import("/codebuild/output/src3991397678/src/packages/nuxt/pages/life-insurance/quote/customer/success.vue")
  },
  {
    name: "dashboard-offers-vertical-claim-offer",
    path: "/dashboard/offers/:vertical()/claim-offer",
    meta: claim_45offerdhW8WLZrH7gxi8yW5MvsxpfFOYiumEuPFHBMYqZ2U3UMeta || {},
    component: () => import("/codebuild/output/src3991397678/src/packages/nuxt/pages/dashboard/offers/[vertical]/claim-offer.vue")
  },
  {
    name: "dashboard-vault-product-lic-details",
    path: "/dashboard/vault/:product([^/]*)*/lic-details",
    meta: lic_45detailsB2ICtTzCsOQkgjttPl_453CVoWo8gC09w0TW5TMItha7gMeta || {},
    component: () => import("/codebuild/output/src3991397678/src/packages/nuxt/pages/dashboard/vault/[...product]/lic-details.vue")
  },
  {
    name: "energy-compare-by-location-coffs-harbour",
    path: "/energy/compare-by-location/coffs-harbour",
    component: () => import("/codebuild/output/src3991397678/src/packages/nuxt/pages/energy/compare-by-location/coffs-harbour.vue")
  },
  {
    name: "health-insurance-quote-policy-step-step",
    path: "/health-insurance/quote/policy/step/:step()",
    meta: _91step_93o1R4c35IaPDBihibgAMjochUvxTnF2Ztl3oLvXWQTLoMeta || {},
    component: () => import("/codebuild/output/src3991397678/src/packages/nuxt/pages/health-insurance/quote/policy/step/[step].vue")
  },
  {
    name: "health-insurance-visitors-visa-ovhc",
    path: "/health-insurance/visitors-visa-ovhc",
    component: () => import("/codebuild/output/src3991397678/src/packages/nuxt/pages/health-insurance/visitors-visa-ovhc/index.vue")
  },
  {
    name: "preview-expert-analysis-vertical-slug",
    path: "/preview/expert-analysis/:vertical()/:slug()",
    component: () => import("/codebuild/output/src3991397678/src/packages/nuxt/pages/preview/expert-analysis/[vertical]/[slug].vue")
  },
  {
    name: "health-insurance-application-payment",
    path: "/health-insurance/application/payment",
    component: () => import("/codebuild/output/src3991397678/src/packages/nuxt/pages/health-insurance/application/payment/index.vue")
  },
  {
    name: "health-insurance-visitors-visa-ovhc-step",
    path: "/health-insurance/visitors-visa-ovhc/:step()",
    component: () => import("/codebuild/output/src3991397678/src/packages/nuxt/pages/health-insurance/visitors-visa-ovhc/[step].vue")
  },
  {
    name: "life-insurance-quote-application-thank-you",
    path: "/life-insurance/quote/application/thank-you",
    component: () => import("/codebuild/output/src3991397678/src/packages/nuxt/pages/life-insurance/quote/application/thank-you.vue")
  },
  {
    name: "health-insurance-application-thankyou",
    path: "/health-insurance/application/thankyou",
    component: () => import("/codebuild/output/src3991397678/src/packages/nuxt/pages/health-insurance/application/thankyou/index.vue")
  },
  {
    name: "health-insurance-rate-rise-calculator",
    path: "/health-insurance/rate-rise-calculator",
    component: () => import("/codebuild/output/src3991397678/src/packages/nuxt/pages/health-insurance/rate-rise-calculator/index.vue")
  },
  {
    name: "health-insurance-visitors-visa-ovhc-details",
    path: "/health-insurance/visitors-visa-ovhc/details",
    component: () => import("/codebuild/output/src3991397678/src/packages/nuxt/pages/health-insurance/visitors-visa-ovhc/details.vue")
  },
  {
    name: "health-insurance-application-payment-expired",
    path: "/health-insurance/application/payment/expired",
    component: () => import("/codebuild/output/src3991397678/src/packages/nuxt/pages/health-insurance/application/payment/expired.vue")
  },
  {
    name: "life-insurance-income-protection-quote",
    path: "/life-insurance/income-protection/quote",
    component: () => import("/codebuild/output/src3991397678/src/packages/nuxt/pages/life-insurance/income-protection/quote/index.vue")
  },
  {
    name: "health-insurance-visitors-visa-ovhc-thank-you",
    path: "/health-insurance/visitors-visa-ovhc/thank-you",
    component: () => import("/codebuild/output/src3991397678/src/packages/nuxt/pages/health-insurance/visitors-visa-ovhc/thank-you.vue")
  },
  {
    name: "life-insurance-income-protection-quote-details",
    path: "/life-insurance/income-protection/quote/details",
    component: () => import("/codebuild/output/src3991397678/src/packages/nuxt/pages/life-insurance/income-protection/quote/details.vue")
  },
  {
    name: "life-insurance-quote-application-pre-assesment",
    path: "/life-insurance/quote/application/pre-assesment",
    component: () => import("/codebuild/output/src3991397678/src/packages/nuxt/pages/life-insurance/quote/application/pre-assesment.vue")
  },
  {
    name: "health-insurance-visitors-visa-ovhc-confirmation",
    path: "/health-insurance/visitors-visa-ovhc/confirmation",
    component: () => import("/codebuild/output/src3991397678/src/packages/nuxt/pages/health-insurance/visitors-visa-ovhc/confirmation.vue")
  },
  {
    name: "dashboard-vault-product-vertical-completion",
    path: "/dashboard/vault/:product([^/]*)*/:vertical()/completion",
    meta: completionU2SEe2KpzrKc_NY_45uFPT6RwCrmcAFmo3u9B4k9ObWxsMeta || {},
    component: () => import("/codebuild/output/src3991397678/src/packages/nuxt/pages/dashboard/vault/[...product]/[vertical]/completion.vue")
  },
  {
    name: "health-insurance-visitors-visa-ovhc-quotes-results",
    path: "/health-insurance/visitors-visa-ovhc/quotes-results",
    component: () => import("/codebuild/output/src3991397678/src/packages/nuxt/pages/health-insurance/visitors-visa-ovhc/quotes-results.vue")
  },
  {
    name: "life-insurance-income-protection-quote-confirmation",
    path: "/life-insurance/income-protection/quote/confirmation",
    component: () => import("/codebuild/output/src3991397678/src/packages/nuxt/pages/life-insurance/income-protection/quote/confirmation.vue")
  },
  {
    name: "dashboard-vault-product-form-energy-step-step",
    path: "/dashboard/vault/:product([^/]*)*/form/energy/step/:step()",
    meta: _91step_93wPUuWbRiO2FKFCe281xn7RjKL5_n6STGqq4MaJ4FhAMMeta || {},
    component: () => import("/codebuild/output/src3991397678/src/packages/nuxt/pages/dashboard/vault/[...product]/form/energy/step/[step].vue")
  },
  {
    name: "dashboard-vault-product-form-generic-step-step",
    path: "/dashboard/vault/:product([^/]*)*/form/generic/step/:step()",
    meta: _91step_937vhIUiTeq8tYUHFAgIHiRLGxjj6YI9FWRojFGYV1eIUMeta || {},
    component: () => import("/codebuild/output/src3991397678/src/packages/nuxt/pages/dashboard/vault/[...product]/form/generic/step/[step].vue")
  },
  {
    name: "health-insurance-visitors-visa-ovhc-application-step",
    path: "/health-insurance/visitors-visa-ovhc/application/:step()",
    component: () => import("/codebuild/output/src3991397678/src/packages/nuxt/pages/health-insurance/visitors-visa-ovhc/application/[step].vue")
  },
  {
    name: "dashboard-vault-product-form-home-loan-step-step",
    path: "/dashboard/vault/:product([^/]*)*/form/home-loan/step/:step()",
    meta: _91step_93ZwWeB8C5o1RHSFHFhKKDBnPRgCCed7d7htxf14VYCOUMeta || {},
    component: () => import("/codebuild/output/src3991397678/src/packages/nuxt/pages/dashboard/vault/[...product]/form/home-loan/step/[step].vue")
  },
  {
    name: "health-insurance-students-offer-university-form",
    path: "/health-insurance/students-offer/:university()/form",
    component: () => import("/codebuild/output/src3991397678/src/packages/nuxt/pages/health-insurance/students-offer/[university]/form/index.vue")
  },
  {
    name: "dashboard-vault-product-vertical-additional-details",
    path: "/dashboard/vault/:product([^/]*)*/:vertical()/additional-details",
    meta: additional_45detailspo64WTaiy3bfeosuOur5sKkci_klFE0Zyg_45LUZyObx4Meta || {},
    component: () => import("/codebuild/output/src3991397678/src/packages/nuxt/pages/dashboard/vault/[...product]/[vertical]/additional-details.vue")
  },
  {
    name: "dashboard-vault-product-form-life-insurance-step-step",
    path: "/dashboard/vault/:product([^/]*)*/form/life-insurance/step/:step()",
    meta: _91step_930aIMZ6xXQuBBB2nqTNIDpkHXMFxBN3G_45lIb2MSn9FvUMeta || {},
    component: () => import("/codebuild/output/src3991397678/src/packages/nuxt/pages/dashboard/vault/[...product]/form/life-insurance/step/[step].vue")
  },
  {
    name: "dashboard-vault-product-form-health-insurance-step-step",
    path: "/dashboard/vault/:product([^/]*)*/form/health-insurance/step/:step()",
    meta: _91step_93sNn1aq_45_45L89B1xCLSlQYburuUA7OfKUJR_GD_458aaI6IMeta || {},
    component: () => import("/codebuild/output/src3991397678/src/packages/nuxt/pages/dashboard/vault/[...product]/form/health-insurance/step/[step].vue")
  },
  {
    name: "health-insurance-students-offer-university-terms-conditions",
    path: "/health-insurance/students-offer/:university()/terms-conditions",
    component: () => import("/codebuild/output/src3991397678/src/packages/nuxt/pages/health-insurance/students-offer/[university]/terms-conditions/index.vue")
  },
  {
    name: "health-insurance-budget-hub-health-insurance-average-cost-calculator",
    path: "/health-insurance/budget-hub/health-insurance-average-cost-calculator",
    component: () => import("/codebuild/output/src3991397678/src/packages/nuxt/pages/health-insurance/budget-hub/health-insurance-average-cost-calculator/index.vue")
  },
  {
    name: "Guides",
    path: "/credit-cards/guides",
    component: () => import("/codebuild/output/src3991397678/src/packages/nuxt/pages/[vertical]/guides.vue")
  },
  {
    name: "Companies",
    path: "/credit-cards/companies",
    component: () => import("/codebuild/output/src3991397678/src/packages/nuxt/pages/[vertical]/companies/index.vue")
  }
]